(function triggerAnimation() {
  const targets = document.querySelectorAll(".Divider__container");
  options = {
    rootMargin: "0px",
    threshold: 1.0,
  };
  // check if IntersectionObserver is supported
  if ("IntersectionObserver" in window) {
    (() => {
      const inView = (target) => {
        const interSecObs = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            const elem = entry.target;
            const player = elem.querySelector("#DividerPlayer");
            entry.isIntersecting ? player.play() : null;
          });
        }, options);
        interSecObs.observe(target);
      };
      targets.forEach(inView);
    })();
  }
})();
