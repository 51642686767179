function mobileMenu() {
  var body = document.body;
  var openedMenu = !body.classList.contains("scroll-lock");
  if (openedMenu) {
    body.classList.add("scroll-lock");
  } else {
    body.classList.remove("scroll-lock");
  }
}

document
  .querySelector("#openSidebarMenu")
  .addEventListener("click", mobileMenu);

document.querySelectorAll("#sidebarMenu > ul > li a").forEach(function (item) {
  //console.log(item);
  item.addEventListener("click", function (e) {
    //console.log(e.currentTarget);
    if (window.innerWidth < 1280) {
      if (e.currentTarget.classList.contains("has-submenu")) {
        e.preventDefault();
        e.currentTarget.parentNode.parentNode.classList.toggle("open-menu");
      }
    }
  });
});

const lotties = document.querySelectorAll(".Menu__lottie");

lotties.forEach((lottie) => {
  const shadow = lottie.shadowRoot;
  var style = document.createElement("style");
  style.innerHTML = "svg{ width:200px !important; cursor: pointer;}";
  shadow.appendChild(style);
});

const playerContainers = document.querySelectorAll(".hoverEffects");

playerContainers.forEach((container) => {
  container.addEventListener("mouseover", () => {
    const player = container.querySelector(".Menu__lottie");
    player.setDirection(1);
    player.play();
  });

  container.addEventListener("mouseleave", () => {
    const player = container.querySelector(".Menu__lottie");
    player.setDirection(-1);
    player.play();
  });
});
