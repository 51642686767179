const tabs = document.querySelectorAll(".Tabs");
const tabcontent = document.querySelectorAll(".Tab__content");
const tablinks = document.querySelectorAll(".tablinks");
const tab = document.querySelector(".Tab__header");

if (tabs && tabs.length) {
  tabcontent.forEach((tab, idx) => {
    if (idx === 0) {
      tab.style.display = "block";
    }
  });
  tablinks.forEach((tabLink, idx) => {
    if (idx === 0) {
      tabLink.className += " current";
    }
  });
}
const openTab = (evt, tabName) => {
  tabcontent.forEach((tab) => {
    tab.style.display = "none";
  });

  tablinks.forEach((link) => {
    link.className = link.className.replace(" current", "");
  });

  document.getElementById(tabName).style.display = "block";
  evt.currentTarget.className += " current";
};

tablinks.forEach((link) => {
  link.addEventListener("click", (e) => {
    openTab(e, e.target.innerText);
  });
});
