var btnScrollDown = document.querySelector(".Header__button");

function scrollDown() {
  var windowCoords = document.querySelector(".Divider__container").offsetTop;

  (function scroll() {
    if (window.pageYOffset < windowCoords) {
      window.scrollBy(0, 10);
      setTimeout(scroll, 0);
    }
    if (window.pageYOffset > windowCoords) {
      window.scrollTo(0, windowCoords);
    }
  })();
}

btnScrollDown.addEventListener("click", scrollDown);
